<template>
  <name-with-avatar
    v-if="obProduct"
    :items="[obProduct]"
    :name="sName"
    only-img-avatar
    v-on="$listeners"
  >
    <template v-if="!hideCode || hasSubtitle" #subtitle>
      <slot name="subtitle">
        <span class="font-weight-bold text-uppercase" v-text="obProduct.code" />
      </slot>
    </template>

    <template #icon>
      <slot name="icon" />
    </template>
  </name-with-avatar>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import CacheMixin from "@/mixins/CacheMixin";
import { Product, ProductData } from "@planetadeleste/vue-mc-shopaholic";

import NameWithAvatar from "@/components/common/NameWithAvatar.vue";
import { get } from "lodash";

@Component({
  components: { NameWithAvatar },
})
export default class ProductPreview extends Mixins(CacheMixin) {
  @Prop(Object) readonly product!: ProductData;
  @Prop(Number) readonly productId!: number;
  @Prop(String) readonly name!: number;
  @Prop(Boolean) readonly hideCode!: number;

  obProduct: Product = new Product();

  get sName() {
    return this.name ?? this.obProduct.name;
  }

  get cacheKey() {
    return "PRODUCT";
  }

  get hasSubtitle() {
    return !!get(this.$slots, "subtitle");
  }

  mounted() {
    this.$nextTick(this.load);
  }

  async load() {
    const iDataId = this.product ? this.product.id : this.productId;
    const obProductData = iDataId ? this.cache(iDataId) : null;

    if (obProductData) {
      this.obProduct = new Product(obProductData);
      return;
    }

    if (this.product && this.product.id) {
      if (this.product instanceof Product) {
        this.obProduct = new Product(this.product.attributes);
      } else {
        this.obProduct = new Product(this.product);
      }

      this.cache(this.obProduct.attributes);
      return;
    }

    if (this.productId) {
      this.obProduct.set("id", this.productId);
      await this.obProduct.fetch();
      this.cache(this.obProduct.attributes);
    }
  }
}
</script>
