<template>
  <sheet-filters
    ref="sheetFilters"
    v-model="obFilterData"
    :model-name="sModelFilterKey"
    :on-reset="setDefaults"
    :persistent="persistent"
    show-reload-button
    @apply="applyFilters"
    @reset="resetFilters"
  >
    <template #default="{ apply }">
      <v-row>
        <template v-if="signed">
          <v-col cols="12" lg="2" md="4">
            <form-field-text
              v-model="obFilterData.order_serial"
              clearable
              label="serie"
              @input:debounce="apply"
            />
          </v-col>

          <v-col cols="12" lg="2" md="4">
            <form-field-text
              v-model="obFilterData.order_number"
              clearable
              label="number"
              @input:debounce="apply"
            />
          </v-col>
        </template>

        <v-col cols="12" lg="4">
          <customer-select
            v-model="obFilterData.customer"
            clearable
            @change="apply"
          />
        </v-col>

        <v-col v-if="movementType.length" cols="12" lg="4">
          <invoice-movement-type-select
            :movement-type-code="movementType"
            :value="iMovementType"
            clearable
            no-autoassign
            validate-company
            @change="onSelectMovementType"
          />
        </v-col>

        <v-col cols="12" lg="4">
          <invoice-type-select
            v-model="obFilterData.invoiceType"
            clearable
            @change="onSelectType"
          />
        </v-col>

        <v-col cols="12" lg="4">
          <currency-select
            v-model="obFilterData.currency"
            clearable
            company-only
            @change="apply"
          />
        </v-col>

        <v-col cols="12" lg="4">
          <product-select v-model="obFilterData.product" @change="apply" />
        </v-col>

        <v-col cols="12" lg="4">
          <v-radio-group v-model="byCashCredit" row>
            <v-radio :label="$t('all')" :value="0" />
            <v-radio :label="$t('cash')" :value="1" />
            <v-radio :label="$t('credit')" :value="2" />
          </v-radio-group>
        </v-col>

        <v-col v-if="signed && showPaidUnpaid" cols="12" lg="4">
          <v-radio-group v-model="byPaidUnpaid" row>
            <v-radio :label="$t('all')" :value="0" />
            <v-radio :label="$t('paid')" :value="1" />
            <v-radio :label="$t('unpaid')" :value="2" />
          </v-radio-group>
        </v-col>

        <v-col v-if="signed && canFilterWithGlobalReferences" cols="12" lg="4">
          <v-checkbox
            v-model="obFilterData.withGlobalReferences"
            :label="$t('invoices.with.global.references')"
            :true-value="1"
          />
        </v-col>
      </v-row>
    </template>
  </sheet-filters>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import InvoicesMixin from "@/modules/invoices/mixins/InvoicesMixin";
import {
  castArray,
  chain,
  cloneDeep,
  find,
  first,
  get,
  has,
  isArray,
  map,
  set,
  toNumber,
  unset,
} from "lodash";

import SheetFilters from "@/components/form/SheetFilters.vue";
import SearchField from "@/components/form/fields/Search.vue";
import CustomerSelect from "@/modules/customers/components/CustomerSelect.vue";
import InvoiceTypeSelect from "@/modules/invoices/components/InvoiceTypeSelect.vue";
import CurrencySelect from "@/modules/currencies/components/CurrencySelect.vue";
import {
  InvoiceMovementTypeData,
  InvoiceType,
  InvoiceTypeData,
} from "@planetadeleste/vue-mc-gw";
import ProductSelect from "@/modules/products/components/ProductSelect.vue";
import InvoiceMovementTypeSelect from "@/modules/invoices/components/InvoiceMovementTypeSelect.vue";
import { MovementTypeModule } from "@/store/movementtype";
import { InvoiceMovementTypeCode, InvoiceTypeCode } from "@/types/utils";

export interface InvoiceFilterData {
  invoice_number?: string;
  order_serial?: string;
  order_number?: string | number;
  customer?: number;
  invoiceType?: number;
  currency?: number;
  product?: number;
  cash?: number;
  credit?: number;
  paid?: number;
  unpaid?: number;
  total_price_value?: string;
  dgi_status?: string;
  signed?: 1 | 0;
  unsigned?: 1 | 0;
  received?: 1 | 0;
  processed?: 1 | 0;
  notProcessed?: 1 | 0;
  withGlobalReferences?: number;
  movementTypeCode?: number[] | number;
  invoiceTypeCode?: number[] | number;
}

@Component({
  components: {
    InvoiceMovementTypeSelect,
    ProductSelect,
    SheetFilters,
    SearchField,
    CustomerSelect,
    InvoiceTypeSelect,
    CurrencySelect,
  },
})
export default class InvoiceFilters extends Mixins(InvoicesMixin) {
  @Prop(Boolean) readonly persistent!: boolean;
  @Prop(Boolean) readonly signed!: boolean;
  @Prop(Boolean) readonly unsigned!: boolean;

  @Ref("sheetFilters") readonly obSheetFilters!: InstanceType<
    typeof SheetFilters
  >;

  obFilterData: InvoiceFilterData = {};
  sInvoiceTypeCode: number | null = null;

  get canFilterWithGlobalReferences() {
    if (!this.sInvoiceTypeCode) {
      return false;
    }

    return [
      InvoiceType.CODE_NCETICKET,
      InvoiceType.CODE_NCEFACTURA,
      InvoiceType.CODE_NDETICKET,
      InvoiceType.CODE_NDEFACTURA,
    ].includes(this.sInvoiceTypeCode);
  }

  get byCashCredit() {
    if (!has(this.obFilterData, "cash") && !has(this.obFilterData, "credit")) {
      return 0;
    }

    return this.obFilterData.cash ? 1 : 2;
  }

  set byCashCredit(sValue: number) {
    if (sValue === 1) {
      set(this.obFilterData, "cash", 1);
      unset(this.obFilterData, "credit");
      this.byPaidUnpaid = 0;
    } else if (sValue === 2) {
      unset(this.obFilterData, "cash");
      set(this.obFilterData, "credit", 1);
    } else {
      unset(this.obFilterData, "cash");
      unset(this.obFilterData, "credit");
      this.byPaidUnpaid = 0;
    }

    this.applyFilters();
  }

  get showPaidUnpaid(): boolean {
    return this.modelFilters.credit === 1;
  }

  get byPaidUnpaid() {
    if (!has(this.obFilterData, "paid") && !has(this.obFilterData, "unpaid")) {
      return 0;
    }

    return this.obFilterData.paid ? 1 : 2;
  }

  set byPaidUnpaid(sValue: number) {
    switch (sValue) {
      case 1:
        set(this.obFilterData, "paid", 1);
        unset(this.obFilterData, "unpaid");
        set(this.obFilterData, "no_check_skip_balance", 1);
        break;

      case 2:
        unset(this.obFilterData, "paid");
        set(this.obFilterData, "unpaid", 1);
        set(this.obFilterData, "no_check_skip_balance", 1);
        break;

      default:
        unset(this.obFilterData, "paid");
        unset(this.obFilterData, "unpaid");
        unset(this.obFilterData, "no_check_skip_balance");
        break;
    }

    this.applyFilters();
  }

  get arMovementTypeList() {
    return MovementTypeModule.items;
  }

  get iMovementType(): number | undefined {
    if (
      !this.obFilterData.movementTypeCode ||
      (isArray(this.obFilterData.movementTypeCode) &&
        this.obFilterData.movementTypeCode.length > 1)
    ) {
      return undefined;
    }

    const sCode = first(
      castArray(this.obFilterData.movementTypeCode)
    ) as number;
    const obItem = find(this.arMovementTypeList, {
      code: sCode,
    }) as Partial<InvoiceMovementTypeData>;

    return obItem?.id;
  }

  get movementType(): number[] {
    const sRouteName = this.$route.name;
    let arCodeList: InvoiceMovementTypeCode[] = [];

    switch (sRouteName) {
      case "invoices.emitted":
      case "invoices.list":
      case "invoices.saved":
        arCodeList = [1, 2, 5, 6, 11, 13];
        break;

      case "invoices.debt":
      case "invoices.debt.emitted":
      case "invoices.debt.saved":
        arCodeList = [7, 14, 15];
        break;

      case "invoices.receipt.emitted":
      case "invoices.receipt.saved":
        arCodeList = [9];
        break;

      case "invoices.received":
      case "invoices.received.list":
      case "invoices.received.unprocessed":
        // arCodeList = [8, 9];
        arCodeList = [];
        break;
    }

    return arCodeList;
  }

  get invoiceTypeCodeList(): InvoiceTypeCode[] {
    if (!this.arMovementTypeList.length || !this.movementType.length) {
      return [];
    }

    return chain(this.arMovementTypeList)
      .filter((obType) => this.movementType.includes(Number(obType.code)))
      .map(
        (obType) =>
          map(obType.invoice_types, (obInvoiceType: InvoiceTypeData) =>
            Number(obInvoiceType.code)
          ) as InvoiceTypeCode[]
      )
      .flattenDeep()
      .uniq()
      .value();
  }

  async onMounted() {
    this.obFilterData = get(cloneDeep(this.filters), this.modelClassName, {});
    this.setDefaults(this.obFilterData);

    if (!this.arMovementTypeList.length) {
      await MovementTypeModule.load();
    }
  }

  onSelectType(obModel: InvoiceType) {
    if (!obModel) {
      return;
    }

    this.sInvoiceTypeCode = toNumber(obModel.code);
  }

  onSelectMovementType(obModel?: InvoiceMovementTypeData) {
    if (!obModel) {
      unset(this.obFilterData, "movementTypeCode");
    } else {
      this.$set(this.obFilterData, "movementTypeCode", [obModel.code]);
    }

    this.applyFilters();
  }

  applyFilters(reset = false) {
    this.setDefaults(this.obFilterData, false, reset);
    this.$emit("apply", this.obFilterData);
  }

  resetFilters() {
    this.setDefaults();

    // const arKeys = keys(this.modelFilters).filter((sKey) => {
    //   if (this.signed) {
    //     return sKey !== "signed";
    //   }
    //   return sKey !== "unsigned";
    // });
    // const obFilterData = omit(this.modelFilters, arKeys);
    // console.log(arKeys);
    // this.obSheetFilters.filter();
  }

  setDefaults(obData: Record<string, any> = {}, merge = false, reset = false) {
    if (reset) {
      this.refreshPagination();
    }

    if (this.signed) {
      set(obData, "signed", 1);
      unset(obData, "unsigned");
    } else {
      set(obData, "unsigned", 1);
      unset(obData, "signed");
    }

    if (this.movementType.length && !has(obData, "movementTypeCode")) {
      set(obData, "movementTypeCode", this.movementType);
    }

    this.onSetFilters(obData, merge);

    return obData;
  }
}
</script>
